/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #434343;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #434343;
}

button {
  font-family: "Montserrat", sans-serif;
}

#root {
  position: relative;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  padding: 24px;
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  z-index: 100;
}

.header.header--active {
  background-color: #fff;
  box-shadow: 0px 4px 5px 0px rgba(116, 127, 224, 0.15);
}

.header.header--active .nav {
  opacity: 1;
}

.header .header__back-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  visibility: initial;
  transition: opacity 0.2s ease-in-out;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media only screen and (min-width: 370px) {
  .header .header__back-icon {
    width: 24px;
    height: 24px;
  }
}

.header .header__back-icon::after {
  content: "";
  display: inline-block;
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #5a5d7a;
  transition: width 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), height 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), opacity 0.3s cubic-bezier(0, 0.22, 0.45, 1.72);
  z-index: 10;
  opacity: 0;
}

.header .header__back-icon:hover svg {
  position: relative;
  fill: #fff;
  z-index: 100;
  transition: all 0.2 ease-in-out;
}

.header .header__back-icon:hover::after {
  width: 40px;
  height: 40px;
  opacity: 1;
  transition: width 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), height 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), opacity 0.1s cubic-bezier(0, 0.22, 0.45, 1.72);
}

.header .header__back-icon--hidden {
  opacity: 0;
  cursor: auto;
}

.header .nav {
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.header .nav .nav-list .nav-list__item {
  display: inline;
  position: relative;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  transition: font-size 0.2s ease-in-out;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.header .nav .nav-list .nav-list__item::after {
  content: "";
  position: absolute;
  top: 140%;
  left: 50%;
  right: 50%;
  height: 2px;
  background-color: #747fe0;
  border-radius: 2px;
  transition: left 0.2s ease-in-out, right 0.2s ease-in-out;
}

.header .nav .nav-list .nav-list__item:hover::after {
  left: 10%;
  right: 10%;
}

.content {
  width: 250px;
  min-width: 250px;
  min-height: calc(100vh - 100px);
  position: relative;
  margin: 0 auto 0 auto;
  padding-top: 100px;
  z-index: 50;
  transition: all 0.2s ease-in-out;
}

@media only screen and (min-width: 370px) {
  .content {
    width: 300px;
    min-width: 300px;
  }
}

@media only screen and (min-width: 630px) {
  .content {
    width: 500px;
    min-width: 500px;
  }
}

@media only screen and (min-width: 760px) {
  .content {
    width: 600px;
    min-width: 600px;
  }
}

@media only screen and (min-width: 1000px) {
  .content {
    width: 830px;
    min-width: 830px;
  }
}

.section-heading {
  text-transform: uppercase;
  margin-bottom: 24px;
  font-weight: 500;
}

.bio .summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 100%;
  margin-bottom: 64px;
}

@media only screen and (min-width: 630px) {
  .bio .summary {
    flex-direction: row;
  }
}

.bio .summary .summary__photo {
  width: 128px;
  margin-bottom: 32px;
}

@media only screen and (min-width: 630px) {
  .bio .summary .summary__photo {
    margin-bottom: 0;
  }
}

.bio .summary .summary-details {
  text-align: center;
}

@media only screen and (min-width: 630px) {
  .bio .summary .summary-details {
    margin-left: 16px;
    text-align: left;
  }
}

.bio .summary .summary-details .summary-details__name {
  font-size: 24px;
  color: #747fe0;
  margin-bottom: 16px;
  font-weight: 500;
}

@media only screen and (min-width: 630px) {
  .bio .summary .summary-details .summary-details__name {
    margin-bottom: 8px;
  }
}

.bio .summary .summary-details .summary-details__summary {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.bio .bio__about-me {
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 32px;
}

.contact .contact-list {
  display: flex;
  flex-direction: column;
}

.contact .contact-list .contact-list__item {
  display: flex;
  align-items: center;
  position: relative;
  align-self: flex-start;
  font-weight: 300;
  margin-bottom: 24px;
}

.contact .contact-list .contact-list__item a {
  margin-left: 16px;
}

.contact .contact-list .contact-list__item svg {
  position: relative;
  transition: fill 0.1s ease-in-out;
}

.contact .contact-list .contact-list__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.contact .contact-list .contact-list__icon::before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #5a5d7a;
  transition: width 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), height 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), opacity 0.2s ease-in-out;
  z-index: 10;
  opacity: 0;
}

.contact .contact-list .contact-list__item:hover {
  cursor: pointer;
}

.contact .contact-list .contact-list__item:hover svg {
  fill: #fff;
  z-index: 100;
}

.contact .contact-list .contact-list__item:hover a {
  text-decoration: underline;
}

.contact .contact-list .contact-list__item:hover .contact-list__icon::before {
  width: 40px;
  height: 40px;
  opacity: 1;
  transition: width 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), height 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), opacity 0.1s ease-in-out;
}

.projects {
  margin-bottom: 32px;
  position: relative;
}

.projects .project-list {
  display: flex;
  flex-wrap: nowrap;
  transition: all 0.5s cubic-bezier(0, 0.22, 0.06, 1.37);
}

.projects .project-list.project-list--no-transition {
  transition: none;
}

.projects .project-list .project {
  width: 210px;
  min-width: 210px;
  height: 350px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: -2px 2px 10px 2px rgba(116, 127, 224, 0.15);
  margin-right: 32px;
  padding: 24px;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media only screen and (min-width: 370px) {
  .projects .project-list .project {
    width: 276px;
    min-width: 276px;
  }
}

@media only screen and (min-width: 630px) {
  .projects .project-list .project {
    width: 222px;
    min-width: 222px;
  }
}

@media only screen and (min-width: 760px) {
  .projects .project-list .project {
    width: 272px;
    min-width: 272px;
  }
}

@media only screen and (min-width: 1000px) {
  .projects .project-list .project {
    width: 247.33px;
    min-width: 247.33px;
  }
}

.projects .project-list .project .project__name {
  text-align: center;
  margin-bottom: 16px;
  transition: opacity 0.4s ease-in-out;
  font-weight: 500;
}

.projects .project-list .project .project__description {
  font-weight: 300;
  line-height: 24px;
  white-space: normal;
  white-space: initial;
  transition: opacity 0.4s ease-in-out;
}

.projects .project-list .project .project__image {
  position: absolute;
  width: 150px;
  left: calc(50% - 75px);
  top: 55%;
  transition: top 0.4s ease-in-out;
  z-index: 10;
}

.projects .project-list .project .project__image--web {
  width: 150px;
  top: 70%;
  left: calc(50% - 75px);
}

@media only screen and (min-width: 370px) {
  .projects .project-list .project .project__image--web {
    width: 200px;
    top: 60%;
    left: calc(50% - 100px);
  }
}

.projects .project-list .project svg {
  position: absolute;
  left: 0;
  bottom: -194px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  z-index: 0;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  transition: all 0.4s ease-in-out;
}

.projects .project-list .project.project--selected, .projects .project-list .project.project--selected:hover {
  -webkit-transform: translate3d(0, -5%, 0);
          transform: translate3d(0, -5%, 0);
  box-shadow: -150px 100px 50px 0px rgba(116, 127, 224, 0.075);
}

.projects .project-list .project.project--selected .project__name, .projects .project-list .project.project--selected:hover .project__name {
  opacity: 0;
}

.projects .project-list .project.project--selected .project__description, .projects .project-list .project.project--selected:hover .project__description {
  opacity: 0;
}

.projects .project-list .project.project--selected .project__image, .projects .project-list .project.project--selected:hover .project__image {
  transition: top 0.4s cubic-bezier(1, -0.55, 0.58, 1);
  top: 100%;
}

.projects .project-list .project.project--selected svg, .projects .project-list .project.project--selected:hover svg {
  transition: all 0.4s cubic-bezier(1, -0.55, 0.58, 1);
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.projects .project-list .project:last-child {
  margin-right: 0;
}

.projects .project-list .project:hover {
  cursor: pointer;
  box-shadow: -100px 50px 50px 0px rgba(116, 127, 224, 0.15);
  -webkit-transform: translate3d(0, -2.5%, 0);
          transform: translate3d(0, -2.5%, 0);
}

.projects .project-list .project:hover .project__image {
  top: 50%;
}

.projects .project-list .project:hover .project__image--web {
  top: 65%;
}

@media only screen and (min-width: 370px) {
  .projects .project-list .project:hover .project__image--web {
    top: 55%;
  }
}

.projects .project-list .project:hover svg {
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
}

.projects .project-list .project--empty {
  width: 210px;
  min-width: 210px;
  height: 350px;
  display: inline-block;
  position: relative;
  padding: 24px;
  box-sizing: border-box;
  opacity: 0;
  margin-right: 32px;
}

@media only screen and (min-width: 370px) {
  .projects .project-list .project--empty {
    width: 276px;
    min-width: 276px;
  }
}

@media only screen and (min-width: 630px) {
  .projects .project-list .project--empty {
    width: 222px;
    min-width: 222px;
  }
}

@media only screen and (min-width: 760px) {
  .projects .project-list .project--empty {
    width: 272px;
    min-width: 272px;
  }
}

@media only screen and (min-width: 1000px) {
  .projects .project-list .project--empty {
    width: 247.33px;
    min-width: 247.33px;
  }
}

.projects .project-list .project--empty:last-child {
  margin-right: 0;
}

.projects .arrow-back,
.projects .arrow-forward {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.projects .arrow-back {
  left: -25px;
  z-index: 200;
}

@media only screen and (min-width: 440px) {
  .projects .arrow-back {
    left: -50px;
  }
}

.projects .arrow-forward {
  right: -25px;
  z-index: 200;
}

@media only screen and (min-width: 440px) {
  .projects .arrow-forward {
    right: -50px;
  }
}

.projects .arrow-back::before,
.projects .arrow-forward::before {
  display: none;
}

@media only screen and (min-width: 440px) {
  .projects .arrow-back::before,
  .projects .arrow-forward::before {
    content: "";
    height: 20px;
    width: 20px;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    background-color: #5a5d7a;
    transition: width 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), height 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), opacity 0.1s ease-in-out;
    z-index: 10;
    opacity: 0;
  }
}

@media only screen and (min-width: 440px) {
  .projects .arrow-back:hover svg,
  .projects .arrow-forward:hover svg {
    position: relative;
    fill: #fff;
    z-index: 20;
  }
}

.projects .arrow-back:hover::before,
.projects .arrow-forward:hover::before {
  width: 40px;
  height: 40px;
  opacity: 1;
  transition: width 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), height 0.4s cubic-bezier(0, 0.22, 0.45, 1.72), opacity 0.05s ease-in-out;
}

.projects__wrapper {
  overflow-x: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
}

.projects__wrapper.hover {
  overflow-x: visible;
}

@media only screen and (min-width: 370px) {
  .projects__wrapper {
    padding: 12px;
  }
}

.selected-project {
  position: absolute;
  background-color: #fff;
  width: auto;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-transform: translate3d(0, -2.5%, 0);
          transform: translate3d(0, -2.5%, 0);
  box-shadow: -150px 100px 50px 0px rgba(116, 127, 224, 0.075);
  transition: left 0.2s ease-in-out, top 0.2s ease-in-out, bottom 0.2s ease-in-out, right 0.2s ease-in-out, opacity 0.2s ease-in-out;
  z-index: 75;
  opacity: 0;
}

.selected-project .content {
  padding-top: 0;
}

.selected-project.selected-project--expanded {
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  min-width: auto;
  overflow: auto;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  height: auto;
  opacity: 1;
}

.project-details {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 130px;
  padding-bottom: 40px;
  text-align: left;
  position: relative;
  z-index: 90;
  transition: all 0.2s ease-in-out;
}

@media only screen and (min-width: 480px) {
  .project-details {
    margin: 0 auto;
    width: 430px;
    min-width: 430px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (min-width: 630px) {
  .project-details {
    width: 500px;
    min-width: 500px;
  }
}

@media only screen and (min-width: 760px) {
  .project-details {
    width: 600px;
    min-width: 600px;
  }
}

@media only screen and (min-width: 1000px) {
  .project-details {
    width: 800px;
    min-width: 800px;
  }
}

.project-details .project-details__title {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: center;
}

.project-details .time-span {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 24px;
  text-align: center;
}

.project-details .time-span .time-span__label {
  font-weight: 500;
}

.project-details .time-span .time-span__range {
  font-weight: 300;
}

.project-details .main-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  margin-bottom: 24px;
}

@media only screen and (min-width: 630px) {
  .project-details .main-details {
    flex-direction: row;
  }
}

.project-details .main-details .main-details__image {
  height: 500px;
}

.project-details .main-details .main-details__content {
  padding: 24px;
}

.project-details .main-details .main-details__about {
  font-weight: 300;
  margin-top: 24px;
  margin-bottom: 24px;
  line-height: 32px;
}

.project-details .main-details .main-details__links {
  display: flex;
  align-items: center;
}

.project-details .main-details .main-details__links a {
  margin-right: 24px;
}

.project-details .main-details .main-details__links a:last-child {
  margin-right: 0px;
}

.project-details .main-details .main-details__links .main-details__play-store {
  width: 140px;
  height: 52px;
  cursor: pointer;
  margin-right: -8px;
  margin-left: -8px;
}

.project-details .project-details__features {
  font-weight: 300;
  margin-bottom: 40px;
}

.project-details .project-details__features .project-details__feature {
  display: block;
  margin-bottom: 16px;
}

.project-details .project-details__video {
  position: relative;
  padding-top: 56.25%;
  margin-bottom: 40px;
  border-radius: 8px;
  overflow: hidden;
}

.project-details .project-details__video .react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.project-details .project-details__technologies {
  margin-bottom: 40px;
}

.project-details .project-details__technologies .project-details__technology {
  display: inline-block;
  position: relative;
  margin-right: 16px;
}

.project-details .project-details__technologies .project-details__technology svg {
  width: 24px;
}

.project-details .project-details__technologies .project-details__technology img {
  width: 24px;
}

.project-details .project-details__technologies .project-details__technology .technology__label {
  position: absolute;
  opacity: 0;
  font-weight: 300;
  top: 140%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  transition: all 0.2s ease-in-out;
}

.project-details .project-details__technologies .project-details__technology:hover .technology__label {
  opacity: 1;
  top: 120%;
}

.project-details .project-decoration {
  position: absolute;
  left: 0;
  bottom: -194px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  z-index: 0;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  transition: all 0.4s ease-in-out;
}

.project-details--web .main-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.project-details--web .main-details .main-details__image {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
}

.project-details--web .main-details .main-details__about {
  margin-top: 16px;
  margin-bottom: 40px;
}

.project-details--web .main-details .main-details__links {
  margin-bottom: 40px;
}

.project-details--web .main-details .section-heading {
  margin-bottom: 0;
  -ms-grid-row-align: start;
  align-self: start;
}

.btn {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.btn.btn--light-red {
  border-color: #ff7674;
  color: #ff7674;
}

.btn.btn--light-red:hover {
  color: #fff;
  background-color: #ff7674;
}

.btn.btn--yellow {
  border-color: #fdca6e;
  color: #fdca6e;
}

.btn.btn--yellow:hover {
  color: #fff;
  background-color: #fdca6e;
}

.btn.btn--blue {
  border-color: #00b8f8;
  color: #00b8f8;
}

.btn.btn--blue:hover {
  color: #fff;
  background-color: #00b8f8;
}

.btn.btn--red {
  border-color: #c32025;
  color: #c32025;
}

.btn.btn--red:hover {
  color: #fff;
  background-color: #c32025;
}

.btn.btn--purple {
  color: #554588;
  border-color: #554588;
}

.btn.btn--purple:hover {
  color: #fff;
  background-color: #554588;
}

.btn.btn--purple-two {
  color: #7c5dfa;
  border-color: #7c5dfa;
}

.btn.btn--purple-two:hover {
  color: #fff;
  background-color: #7c5dfa;
}

.btn.btn--yellow-two {
  color: #fda214;
  border-color: #fda214;
}

.btn.btn--yellow-two:hover {
  color: #fff;
  background-color: #fda214;
}

.footer {
  padding: 16px;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  border-top: 1px solid #eee;
}

.dragger-outer {
  padding: 12px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.dragger-outer.hover {
  overflow-x: visible;
}

.fadeInFromBottom-appear {
  opacity: 0.01;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
}

.fadeInFromBottom-appear.fadeInFromBottom-appear-active {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.fadeIn-appear {
  opacity: 0.01;
}

.fadeIn-appear.fadeIn-appear-active {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.fadeInFromBottom-enter {
  opacity: 0.01;
  -webkit-transform: translateY(200px);
          transform: translateY(200px);
}

.fadeInFromBottom-enter.fadeInFromBottom-enter-active {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.fadeInFromBottom-leave {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.fadeInFromBottom-leave.fadeInFromBottom-leave-active {
  opacity: 0.01;
  -webkit-transform: translateY(200px);
          transform: translateY(200px);
  transition: all 0.2s ease-in-out;
}
/*# sourceMappingURL=App.css.map */
